@import "../variables.css";

.home-wrapper{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    min-height: 100vh;
    gap: 10px;
}

.home-wrapper h1{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 60px;
    line-height: 70px;
    background: linear-gradient(90deg, #883BE5 0%, #60B2D1 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    margin-bottom: 0;
}

.home-wrapper h2{
    font-size: 24px;
}

.quick-selection-wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid #C5C5C5;
    border-radius: 16px;
    gap: 10px;
    padding: 30px;
    margin: 20px;
    max-width: 850px;
    min-width: 650px;
    width: 50%;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
    background-color: white;
}

.quick-selection-wrapper h{
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 20px;
}

.switch-search{
    margin: 20px;
    cursor: pointer;
    color: var(--color-blue-primary);
}

.switch-search:hover{
    text-decoration: underline;
    color: var(--color-blue-hover);
}

.right-wrapper{
    position: absolute;
    bottom: 40px;
    display: flex;
    flex-direction: row;
    gap: 20px;
    margin: 20px;
}

@media screen and (max-width: 600px) {
    .home-wrapper {
      height: auto;
    }

    .quick-selection-wrapper {
        margin: 30px 40px;
        min-width: auto;
    }

    .quick-selection-wrapper h {
        font-size: 24px;
    }
    .right-wrapper{
        position: relative;
        bottom: auto;
    }
  }