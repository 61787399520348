@import "../../variables.css";

.bar-wrapper {
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 280px;
  height: auto;
  margin: 20px 40px;
}

.left-logo-wrap {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background-color: white;
  border-radius: 100px;
  width: 280px;
  padding: 5px;
  text-decoration: none;
}

.left-logo-wrap h {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 33px;
  margin: 0;
  padding-right: 10px;
  color: var(--color-text-color);
}

.left-logo-wrap img {
  width: 60px;
}

.bar-header span {
  display: none;
}

@media screen and (max-width: 600px) {
  .bar-header span {
    display: block;
  }

  .left-wrapper {
    margin: 14px 24px;
  }

  .left-logo-wrap {
    gap: 8px;
    width: 142px;
    padding: 4px;
  }

  .left-logo-wrap h {
    font-size: 14px;
  }

  .left-logo-wrap img {
    width: 36px;
  }

  .ud-wrapper {
    width: 146px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .home-wrapper h1 {
    line-height: 37.5px !important;
    font-size: 32px !important;
    padding: 0 !important;
    margin: 0 34px;
  }

  .home-wrapper h2 {
    font-size: 14px !important;
    margin: 24px 84px;
  }

  .menus {
    flex-direction: column;
  }

  .menu-items {
    font-size: 16px !important;
  }

  .bar-wrapper {
    display: none;
  }

  .bar-wrapper .bar-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 20px;
  }

}
