@import "../../variables.css";

.right-main-wrapper {
  padding: 40px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: white;
  border-radius: 10px;
}

.right-header {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  margin-top: 10px;
}

.right-header h {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 47px;
  /* identical to box height */

  background: linear-gradient(90deg, #883be5 0%, #60b2d1 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.right-header .mobile-header {
  display: none;
}

.right-header .mobile-menu {
  display: none;
}

.no-decoration {
  text-decoration: none;
  color: black;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
}

.no-decoration p{
  margin: 0;
}

.buy-now-b {
  width: 100px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 6px;
  isolation: isolate;
  background: var(--color-blue-primary);
  border-radius: 8px;
  color: white;
  border: none;
  min-width: 100px;
  margin: 5px;
}

.buy-now-b:hover {
  background: var(--color-blue-hover);
}

@media screen and (max-width: 600px) {

  .right-header h {
    display: none;
  }

  .right-header button {
    display: flex;
    flex-direction: row;
    border: 1px solid grey;
    background: white;
    border-radius: 50px;
  }

  .mobile-menu button{
    margin: 24px 28px 0 0;
    border: none;
    margin: 0;
  }

  .right-header button.mobile-header {
    display: flex;
    flex-direction: row;
  }

  .right-header button.mobile-header h {
    display: block;
    font-size: 14px;
  }

  .MuiBox-root .table-tab {
    min-width: auto;
  }

  .MuiPaper-root .MuiToolbar-root {
    padding: 0;
  }

  .MuiPaper-root td {
    padding: 0;
  }

  .right-header .mobile-menu {
    display: block;
  }

  .right-main-wrapper {
    width: 100vw ;
    padding: 0 ;
  }
}
