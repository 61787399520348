@import url(https://fonts.googleapis.com/css?family=Rubik);
@import url(https://fonts.googleapis.com/css?family=Roboto);
:root {
    --color-blue-primary: #4650EF;
    --color-blue-hover: #0D19D9;
    --color-white-primary: #F5F5F5;
    --color-white-hover: #DFE6F3;
    --color-text-color: #262626;
    --color-border-color:#CBD5E0;
  }
body {
  margin: 0;
  font-family: 'Rubik',-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--color-white-primary);
  color: var(--color-text-color);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
  font-family: 'Rubik';
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.select-tab {
  width: 300px;
  height: 80vh;
  border-right: 1px solid lightgrey;
  display: flex;
  flex-direction: column;
  padding: 40px;
  grid-gap: 10px;
  gap: 10px;
  font-weight: 700;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.search-window {
  width: 100%;
  padding: 40px 40px 0 10px;
  height: 100%;
}

.button-wrapper {
  position: relative;
  left: 10px;
  display: flex;
  flex-direction: row;
  grid-gap: 20px;
  gap: 20px;
  padding: 0 0 20px 0;
}

.select-tab h {
  font-size: 24px;
  color: #1876d1;
}

.tab-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* border: 1px solid gray; */
  background-color: whitesmoke;
  border-radius: 10px;
  padding: 0 10px 0 10px;
}
.fullwidth {
  width: 100%;
}

.header-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 0 40px 0 40px;
  width: 100%;
}
.left-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  height: 80px;
  grid-gap: 20px;
  gap: 20px;
  width: 100%;
}

.left-wrapper img {
  height: 100%;
}

.ud-wrapper {
  width: 280px;
  padding: 5px;
  background-color: white;
  border-radius: 100px;
}

.ud-wrapper img {
  width: 70%;
}

.social-meida-wrapper {
  display: flex;
  flex-direction: row;
  grid-gap: 15px;
  gap: 15px;
  margin-right: 20px;
}

.header-wrapper h {
  font-size: 18px;
  font-weight: 700;
  line-break: loose;
  color: #1876d1;
}

.header-wrapper p {
  font-size: 28px;
  font-weight: 700;
  line-break: loose;
}

.result-wrapper {
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  gap: 10px;
  padding: 0 20px 30px 20px;
  margin: 10px;
}
.result-wrapper h {
  font-size: 18px;
  font-weight: 700;
}

.result-wrapper p {
  padding: 10px;
  margin: 0;
}

.result-wrapper span {
  font-style: italic;
  font-weight: 700;
  color: blue;
}

.result-details-box {
  border: 1px solid #1876d1;
  border-radius: 10px;
  min-height: 500px;
}

.result-details-wrapper {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  padding: 10px;
  grid-gap: 10px;
  gap: 10px;
}

.result-details-wrapper h2 {
  width: 100%;
}

.domain-card {
  width: 200px;
  height: 50px;
  border: 2px solid black;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px 0 10px;
}
.text-wrapper {
  display: flex;
  flex-direction: column;
  font-size: 14px;
}
.text-wrapper p {
  margin: 0;
  padding: 0;
}
.text-wrapper h {
  font-weight: 700;
  font-size: 14px;
  margin: 0;
}
.no-decoration {
  text-decoration: none;
  color: black;
}

@media screen and (max-width: 600px) {
  .social-meida-wrapper {
    display: none;
  }
}

.qselection-wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    grid-gap: 12px;
    gap: 12px;
}

.qselection-wrapper h{
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 20px;
}

.single-card-wrapper{
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
    width: 280px;
}
.single-card-wrapper-top{
    background-color: white;
    border-radius: 20px;
    border: 1px solid var(--color-border-color);
    padding: 5px;
}
.topButton{
    display: flex;
    flex-direction: row;
    background-color: transparent;
    border: none;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    cursor: pointer;
    margin: 3px 0 3px 0;
}
.topButton:hover{
    color: var(--color-blue-hover);
}
.right-shift{
    position: relative;
    left: 10px;
}
.button-active{
    color: var(--color-blue-primary);
    font-weight: bold;
    text-decoration:underline
}
.search-button{
    width: 280px;
    height: 40px;
    background-color: var(--color-blue-primary);
    color: white;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    border: none;
    border-radius: 12px;
    cursor: pointer;
    transition-duration: 0.2s;
}
.search-button:hover{
    background-color: var(--color-blue-hover);
}

.search-input{
    width: 100%;
    display: flex;
    flex-direction: column;
    grid-gap: 10px;
    gap: 10px;
}

.length-chars{
    width: 100%;
    display: flex;
    flex-direction: row;
    grid-gap: 10px;
    gap: 10px;
}

.box-input{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    grid-gap: 4px;
    gap: 4px;
}

.half-input{
    width: 50%;
}

.full-input{
    width: 100%;
}
.one-three-input{
    width: 33%;
}

.box-input h{
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
}

.input-block{
    width: 100%;
}
.bar-wrapper {
  display: flex;
  flex-direction: column;
  grid-gap: 30px;
  gap: 30px;
  width: 280px;
  height: auto;
  margin: 20px 40px;
}

.left-logo-wrap {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
  background-color: white;
  border-radius: 100px;
  width: 280px;
  padding: 5px;
  text-decoration: none;
}

.left-logo-wrap h {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 33px;
  margin: 0;
  padding-right: 10px;
  color: var(--color-text-color);
}

.left-logo-wrap img {
  width: 60px;
}

.bar-header span {
  display: none;
}

@media screen and (max-width: 600px) {
  .bar-header span {
    display: block;
  }

  .left-wrapper {
    margin: 14px 24px;
  }

  .left-logo-wrap {
    grid-gap: 8px;
    gap: 8px;
    width: 142px;
    padding: 4px;
  }

  .left-logo-wrap h {
    font-size: 14px;
  }

  .left-logo-wrap img {
    width: 36px;
  }

  .ud-wrapper {
    width: 146px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .home-wrapper h1 {
    line-height: 37.5px !important;
    font-size: 32px !important;
    padding: 0 !important;
    margin: 0 34px;
  }

  .home-wrapper h2 {
    font-size: 14px !important;
    margin: 24px 84px;
  }

  .menus {
    flex-direction: column;
  }

  .menu-items {
    font-size: 16px !important;
  }

  .bar-wrapper {
    display: none;
  }

  .bar-wrapper .bar-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 20px;
  }

}

.mainBox-wrapper{
    display: flex;
    flex-direction: row;
    grid-gap: 20px;
    gap: 20px;
}

.menus {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    list-style: none;
    grid-gap: 30px;
    gap: 30px;
    padding: 0;
  }
  
  .menu-items {
    position: relative;
    font-size: 14px;
  }
  
  .menu-items a {
    display: block;
    font-size: inherit;
    color: inherit;
    text-decoration: none;
    cursor: pointer;
  }
  
  .menu-items button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: inherit;
    font-size: inherit;
    background-color: transparent;
    cursor: pointer;
    width: 100%;
  }
  
  button span {
    margin-left: 3px;
  }
  
  .menu-items > a, .menu-items button {
    text-align: left;
    padding: 0.7rem 1rem;
  }
  
  .menu-items a:hover,
  .menu-items button:hover {
    background-color: #f2f2f2;
  }
  
  .arrow::after {
    content: "";
    display: inline-block;
    margin-left: 0.28em;
    vertical-align: 0.09em;
    border-top: 0.42em solid;
    border-right: 0.32em solid transparent;
    border-left: 0.32em solid transparent;
  }
  
  .dropdown {
    position: absolute;
    right: 0;
    left: auto;
    background: #FFFFFF;
    border: 1px solid #CBD5E0;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
    border-radius: 12px;
    font-size: 0.875rem;
    z-index: 9999;
    min-width: 10rem;
    padding: 0.5rem 0;
    list-style: none;
    display: none;
  }
  
  .dropdown.show {
    display: block;
  }
  
  .dropdown .dropdown-submenu {
    position: absolute;
    left: 100%;
    padding-bottom: 10px;
  }
  
  /* content */
  
  .content {
    max-width: 1200px;
    margin: 0 auto;
    padding: 3rem 20px;
  }
  
  .content h2 {
    margin-bottom: 1rem;
  }
  
  .content a {
    color: #cc3852;
    margin-right: 10px;
  }

  .top-level-button{
      border: 1px solid #CBD5E0;
      border-radius: 100px;
  }

  .no-border{
      border: none;
  }


.shift2{
  top:-100%;
}

.shift3{
  top:-100%;
}

.shift4{
  top:-200%;
}

.shift5{
  top:-300%;
}
.shift6{
  top:-400%;
}
.shift7{
  top:-500%;
}
.shift8{
  top:-600%;
}
.shift9{
  top:-700%;
}
.shift10{
    top:-800%;
  }
.shift11{
    top:-900%;
  }
.shift12{
  top:-1000%;
}
.shift13{
    top:-1100%;
  }


.accordion-wrapper{
    width: auto;
}

.accordion-button{
    background-color: white;
    width: 160px;
    height: 40px;
    border: 1px solid #CBD5E0;
    border-radius: 20px;
    transition-duration: 0.2s;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    grid-gap: 15px;
    gap: 15px;
}
.accordion-button:hover{
    background-color: grey;
}

.accordion-expand{
    display: flex;
    flex-direction: column;
    width: 160px;
    border: 1px solid #CBD5E0;
    height: auto;
    margin-top: 5px;
}

.accordion-tab{
    position: relative;
    width: 160px;
    height: 40px;
    border: 0;
    background-color: white;
    transition-duration: 0.2s;
}

.accordion-tab:hover{
    background-color: grey;
}

.accordion-tab-active{
    background-color: gray;
}

.accordion-second-tab{
    left: 100px;
}

.second-wrapper{
    position: relative;
    left: 5px;
    width: 160px;
    display: flex;
    flex-direction: column;
    border: 1px solid #CBD5E0;
}

.first-second{
    display: flex;
    flex-direction: row;
    width: 330px;
    height: 40px;
    justify-content: flex-start;
    align-items: flex-start;
}
.home-wrapper{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    min-height: 100vh;
    grid-gap: 10px;
    gap: 10px;
}

.home-wrapper h1{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 60px;
    line-height: 70px;
    background: linear-gradient(90deg, #883BE5 0%, #60B2D1 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    margin-bottom: 0;
}

.home-wrapper h2{
    font-size: 24px;
}

.quick-selection-wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid #C5C5C5;
    border-radius: 16px;
    grid-gap: 10px;
    gap: 10px;
    padding: 30px;
    margin: 20px;
    max-width: 850px;
    min-width: 650px;
    width: 50%;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
    background-color: white;
}

.quick-selection-wrapper h{
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 20px;
}

.switch-search{
    margin: 20px;
    cursor: pointer;
    color: var(--color-blue-primary);
}

.switch-search:hover{
    text-decoration: underline;
    color: var(--color-blue-hover);
}

.right-wrapper{
    position: absolute;
    bottom: 40px;
    display: flex;
    flex-direction: row;
    grid-gap: 20px;
    gap: 20px;
    margin: 20px;
}

@media screen and (max-width: 600px) {
    .home-wrapper {
      height: auto;
    }

    .quick-selection-wrapper {
        margin: 30px 40px;
        min-width: auto;
    }

    .quick-selection-wrapper h {
        font-size: 24px;
    }
    .right-wrapper{
        position: relative;
        bottom: auto;
    }
  }
.right-main-wrapper {
  padding: 40px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  gap: 10px;
  background-color: white;
  border-radius: 10px;
}

.right-header {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  margin-top: 10px;
}

.right-header h {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 47px;
  /* identical to box height */

  background: linear-gradient(90deg, #883be5 0%, #60b2d1 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.right-header .mobile-header {
  display: none;
}

.right-header .mobile-menu {
  display: none;
}

.no-decoration {
  text-decoration: none;
  color: black;
  display: flex;
  flex-direction: row;
  grid-gap: 10px;
  gap: 10px;
  align-items: center;
}

.no-decoration p{
  margin: 0;
}

.buy-now-b {
  width: 100px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 6px;
  isolation: isolate;
  background: var(--color-blue-primary);
  border-radius: 8px;
  color: white;
  border: none;
  min-width: 100px;
  margin: 5px;
}

.buy-now-b:hover {
  background: var(--color-blue-hover);
}

@media screen and (max-width: 600px) {

  .right-header h {
    display: none;
  }

  .right-header button {
    display: flex;
    flex-direction: row;
    border: 1px solid grey;
    background: white;
    border-radius: 50px;
  }

  .mobile-menu button{
    margin: 24px 28px 0 0;
    border: none;
    margin: 0;
  }

  .right-header button.mobile-header {
    display: flex;
    flex-direction: row;
  }

  .right-header button.mobile-header h {
    display: block;
    font-size: 14px;
  }

  .MuiBox-root .table-tab {
    min-width: auto;
  }

  .MuiPaper-root .MuiToolbar-root {
    padding: 0;
  }

  .MuiPaper-root td {
    padding: 0;
  }

  .right-header .mobile-menu {
    display: block;
  }

  .right-main-wrapper {
    width: 100vw ;
    padding: 0 ;
  }
}

.apphome-wrapper{
    display: flex;
    flex-direction: row;
    width: 100%;
}
