.select-tab {
  width: 300px;
  height: 80vh;
  border-right: 1px solid lightgrey;
  display: flex;
  flex-direction: column;
  padding: 40px;
  gap: 10px;
  font-weight: 700;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.search-window {
  width: 100%;
  padding: 40px 40px 0 10px;
  height: 100%;
}

.button-wrapper {
  position: relative;
  left: 10px;
  display: flex;
  flex-direction: row;
  gap: 20px;
  padding: 0 0 20px 0;
}

.select-tab h {
  font-size: 24px;
  color: #1876d1;
}

.tab-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* border: 1px solid gray; */
  background-color: whitesmoke;
  border-radius: 10px;
  padding: 0 10px 0 10px;
}
.fullwidth {
  width: 100%;
}

.header-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 0 40px 0 40px;
  width: 100%;
}
.left-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  height: 80px;
  gap: 20px;
  width: 100%;
}

.left-wrapper img {
  height: 100%;
}

.ud-wrapper {
  width: 280px;
  padding: 5px;
  background-color: white;
  border-radius: 100px;
}

.ud-wrapper img {
  width: 70%;
}

.social-meida-wrapper {
  display: flex;
  flex-direction: row;
  gap: 15px;
  margin-right: 20px;
}

.header-wrapper h {
  font-size: 18px;
  font-weight: 700;
  line-break: loose;
  color: #1876d1;
}

.header-wrapper p {
  font-size: 28px;
  font-weight: 700;
  line-break: loose;
}

.result-wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 0 20px 30px 20px;
  margin: 10px;
}
.result-wrapper h {
  font-size: 18px;
  font-weight: 700;
}

.result-wrapper p {
  padding: 10px;
  margin: 0;
}

.result-wrapper span {
  font-style: italic;
  font-weight: 700;
  color: blue;
}

.result-details-box {
  border: 1px solid #1876d1;
  border-radius: 10px;
  min-height: 500px;
}

.result-details-wrapper {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  padding: 10px;
  gap: 10px;
}

.result-details-wrapper h2 {
  width: 100%;
}

.domain-card {
  width: 200px;
  height: 50px;
  border: 2px solid black;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px 0 10px;
}
.text-wrapper {
  display: flex;
  flex-direction: column;
  font-size: 14px;
}
.text-wrapper p {
  margin: 0;
  padding: 0;
}
.text-wrapper h {
  font-weight: 700;
  font-size: 14px;
  margin: 0;
}
.no-decoration {
  text-decoration: none;
  color: black;
}

@media screen and (max-width: 600px) {
  .social-meida-wrapper {
    display: none;
  }
}
