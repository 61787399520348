.accordion-wrapper{
    width: auto;
}

.accordion-button{
    background-color: white;
    width: 160px;
    height: 40px;
    border: 1px solid #CBD5E0;
    border-radius: 20px;
    transition-duration: 0.2s;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 15px;
}
.accordion-button:hover{
    background-color: grey;
}

.accordion-expand{
    display: flex;
    flex-direction: column;
    width: 160px;
    border: 1px solid #CBD5E0;
    height: auto;
    margin-top: 5px;
}

.accordion-tab{
    position: relative;
    width: 160px;
    height: 40px;
    border: 0;
    background-color: white;
    transition-duration: 0.2s;
}

.accordion-tab:hover{
    background-color: grey;
}

.accordion-tab-active{
    background-color: gray;
}

.accordion-second-tab{
    left: 100px;
}

.second-wrapper{
    position: relative;
    left: 5px;
    width: 160px;
    display: flex;
    flex-direction: column;
    border: 1px solid #CBD5E0;
}

.first-second{
    display: flex;
    flex-direction: row;
    width: 330px;
    height: 40px;
    justify-content: flex-start;
    align-items: flex-start;
}