@import "../../../variables.css";

.qselection-wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 12px;
}

.qselection-wrapper h{
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 20px;
}

.single-card-wrapper{
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
    width: 280px;
}
.single-card-wrapper-top{
    background-color: white;
    border-radius: 20px;
    border: 1px solid var(--color-border-color);
    padding: 5px;
}
.topButton{
    display: flex;
    flex-direction: row;
    background-color: transparent;
    border: none;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    cursor: pointer;
    margin: 3px 0 3px 0;
}
.topButton:hover{
    color: var(--color-blue-hover);
}
.right-shift{
    position: relative;
    left: 10px;
}
.button-active{
    color: var(--color-blue-primary);
    font-weight: bold;
    text-decoration:underline
}