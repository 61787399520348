
.menus {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    list-style: none;
    gap: 30px;
    padding: 0;
  }
  
  .menu-items {
    position: relative;
    font-size: 14px;
  }
  
  .menu-items a {
    display: block;
    font-size: inherit;
    color: inherit;
    text-decoration: none;
    cursor: pointer;
  }
  
  .menu-items button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: inherit;
    font-size: inherit;
    background-color: transparent;
    cursor: pointer;
    width: 100%;
  }
  
  button span {
    margin-left: 3px;
  }
  
  .menu-items > a, .menu-items button {
    text-align: left;
    padding: 0.7rem 1rem;
  }
  
  .menu-items a:hover,
  .menu-items button:hover {
    background-color: #f2f2f2;
  }
  
  .arrow::after {
    content: "";
    display: inline-block;
    margin-left: 0.28em;
    vertical-align: 0.09em;
    border-top: 0.42em solid;
    border-right: 0.32em solid transparent;
    border-left: 0.32em solid transparent;
  }
  
  .dropdown {
    position: absolute;
    right: 0;
    left: auto;
    background: #FFFFFF;
    border: 1px solid #CBD5E0;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
    border-radius: 12px;
    font-size: 0.875rem;
    z-index: 9999;
    min-width: 10rem;
    padding: 0.5rem 0;
    list-style: none;
    display: none;
  }
  
  .dropdown.show {
    display: block;
  }
  
  .dropdown .dropdown-submenu {
    position: absolute;
    left: 100%;
    padding-bottom: 10px;
  }
  
  /* content */
  
  .content {
    max-width: 1200px;
    margin: 0 auto;
    padding: 3rem 20px;
  }
  
  .content h2 {
    margin-bottom: 1rem;
  }
  
  .content a {
    color: #cc3852;
    margin-right: 10px;
  }

  .top-level-button{
      border: 1px solid #CBD5E0;
      border-radius: 100px;
  }

  .no-border{
      border: none;
  }


.shift2{
  top:-100%;
}

.shift3{
  top:-100%;
}

.shift4{
  top:-200%;
}

.shift5{
  top:-300%;
}
.shift6{
  top:-400%;
}
.shift7{
  top:-500%;
}
.shift8{
  top:-600%;
}
.shift9{
  top:-700%;
}
.shift10{
    top:-800%;
  }
.shift11{
    top:-900%;
  }
.shift12{
  top:-1000%;
}
.shift13{
    top:-1100%;
  }

