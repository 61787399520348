@import "../../../variables.css";

.search-button{
    width: 280px;
    height: 40px;
    background-color: var(--color-blue-primary);
    color: white;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    border: none;
    border-radius: 12px;
    cursor: pointer;
    transition-duration: 0.2s;
}
.search-button:hover{
    background-color: var(--color-blue-hover);
}

.search-input{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.length-chars{
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.box-input{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 4px;
}

.half-input{
    width: 50%;
}

.full-input{
    width: 100%;
}
.one-three-input{
    width: 33%;
}

.box-input h{
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
}

.input-block{
    width: 100%;
}